import React from 'react';
import { Helmet } from 'react-helmet';

import { css } from '@emotion/react';

import { Footer } from '../components/Footer';
import SiteNav from '../components/header/SiteNav';
import { PostFullContent } from '../components/PostContent';
import { Wrapper } from '../components/Wrapper';
import IndexLayout from '../layouts';
import {
  inner,
  outer,
  SiteArchiveHeader,
  SiteHeader,
  SiteMain,
  SiteNavMain,
} from '../styles/shared';
import { NoImage, PostFull, PostFullHeader, PostFullTitle } from '../templates/post';
import { colors } from '../styles/colors';

const PageTemplate = css`
  .site-main {
    margin-top: 64px;
    padding-bottom: 4vw;
    background: #fff;
  }

  @media (prefers-color-scheme: dark) {
    .site-main {
      /* background: var(--darkmode); */
      background: ${colors.darkmode};
    }
  }
`;

const PrivacyPolicy: React.FunctionComponent = () => (
  <IndexLayout>
    <Helmet>
      <title>PrivacyPolicy</title>
    </Helmet>
    <Wrapper css={PageTemplate}>
      <header className="site-archive-header no-image" css={[SiteHeader, SiteArchiveHeader]}>
        <div css={[outer, SiteNavMain]}>
          <div css={inner}>
            <SiteNav isHome={false} />
          </div>
        </div>
      </header>
      <main id="site-main" className="site-main" css={[SiteMain, outer]}>
        <article className="post page" css={[PostFull, NoImage]}>
          <PostFullHeader>
            <PostFullTitle>個人情報保護方針</PostFullTitle>
          </PostFullHeader>
          <PostFullContent className="post-full-content">
            <div className="post-content">
              <h2>個人情報保護方針</h2>
              <h3>基本方針</h3>
              <p>
                弊社は、個人情報保護の重要性に鑑みて、業務に従事する全ての者がその責任を認識し、個人情報保護に関する法律（個人情報保護法）その他の関連法令・ガイドラインを遵守して個人情報を適正に取り扱い、安全管理について適切な措置を講じます。
              </p>
              <h3>1. 個人情報の取得</h3>
              <p>
                弊社は、業務上必要な範囲内かつ、適法で公正な手段により個人情報を取得します。
              </p>
              <h3>2. 個人情報の利用目的</h3>
              <p>
                弊社は、取得した個人情報を、お客様ご本人の同意なく利用目的の範囲を超えて利用することはありません。当社が取得した個人情報は、以下の目的に利用します。
              </p>
              <ul>
                <li>サービスに関する営業上のご案内</li>
                <li>資料の発送および取扱いサービスに関する書類の送付</li>
                <li>サービスについてのサポートおよびアフターサービス</li>
                <li>寄せられた質問に対する回答</li>
                <li>サービスの品質向上・改善</li>
              </ul>
              <h3>3. 個人情報の安全管理措置</h3>
              <p>
                弊社は、取り扱う個人データの安全管理のため、安全管理に関する取扱規程等の整備および実施体制の整備等、十分なセキュリティ対策を講じるとともに、正確性・最新性を確保するために、必要かつ適切な措置を講じ、万が一、問題等が発生した場合は、速やかに適当な是正対策をします。
              </p>
              <h3>4. 個人情報の第三者への提供</h3>
              <p>
                弊社は、以下の場合を除き、ご本人の同意なく第三者に個人データを提供しません。
              </p>
              <ul>
                <li>法令に基づく場合</li>
                <li>弊社の業務遂行上必要な範囲内で、協力会社に個人情報を提供する必要がある場合。なお、その場合でも業務委託先に対し、提供した個人情報の適正な取り扱いを求めるとともに適切な管理をします。</li>
                <li>人の生命、身体又は財産の保護のために必要であって、お客様の同意を取ることが困難な場合</li>
              </ul>
              <h3>5. 弊社に対するお問い合わせ</h3>
              <p>
                弊社の個人情報の取扱いや、お客様の個人情報に関するご照会・ご相談は、<a href="mailto:s@abplus">こちら</a>までお問い合わせください。<br/>なお、お客様の個人情報に関するご照会に際しては、ご本人であることを確認のうえで可能な限り速やかに対応させていただきます。
              </p>
            </div>
          </PostFullContent>
        </article>
      </main>
      <Footer/>
    </Wrapper>
  </IndexLayout>
);

export default PrivacyPolicy;
